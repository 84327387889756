import { Mutation } from 'app/types/mutation'
import { gql } from 'graphql-request'

export const createMemberTeamMutation = () => gql`
  mutation createMemberTeam($team: String!, $seasonId: String!) {
    createMemberTeam(team: $team, seasonId: $seasonId) {
      success
    }
  }
`

export const memberTeamResult = (data: any) => {
  return {
    success: data?.createMemberTeam?.success || false,
    reason: data?.createMemberTeam?.success ? 'Team selected successfully' : 'Team selection - an error occurred. Try Again.',
  } as Mutation
}
