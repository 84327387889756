import React from 'react'
import { SizableText, YStack, useMedia } from '@mythical/ui'
import {
  QnAQuestionChoiceAnswerType,
  QnAQuestionChoiceType,
  QnAUserAnswer,
} from '../../QnACard.container'
import QnATimer from '../../QnAQuestionTimer'
import { Button } from 'app/components/primitive/Button'
import { QnAAnswer } from '../../QnAAnswer'
import { QnAQuestionHeading } from '../../QnAQuestionHeading'

export interface QnAQuestionChoiceProps {
  question: QnAQuestionChoiceType
  onTimeOut: (data: QnAUserAnswer) => void
  onSubmit: (data: QnAUserAnswer) => void
  submitting?: boolean
}

export const QnAQuestionChoice = ({
  question,
  onSubmit,
  onTimeOut,
  submitting,
}: QnAQuestionChoiceProps) => {
  const media = useMedia()

  const answersWithOrder = question.answers.map((answer, index) => ({
    ...answer,
    order: String.fromCharCode(65 + index),
  }))

  const [data, setData] = React.useState<QnAQuestionChoiceAnswerType[]>([])

  const handleSelect = (answer: QnAQuestionChoiceAnswerType) => {
    const index = data.findIndex((item) => item.id === answer.id)
    if (index > -1) {
      const newData = [...data]
      newData.splice(index, 1)
      setData(newData)
    } else {
      if (question.maxChoices === 1 || question.__typename === 'SingleChoice') {
        setData([answer])
        return
      }
      if (data.length >= question.maxChoices) return
      setData([...data, answer])
    }
  }

  return (
    <YStack gap="$2.5" w="100%" $gtXxs={{ gap: '$3.5' }}>
      <YStack gap="$3.5" ai="center">
        {question.timeLimit > 0 && (
          <QnATimer
            size={media.gtSm ? 70 : 42}
            strokeWidth={media.gtSm ? 7 : 4}
            fontSize={media.gtSm ? 32 : 24}
            duration={question.timeLimit}
            onComplete={() =>
              onTimeOut({
                answerIds: data.map((a) => a.id),
                questionId: question.id,
              })
            }
            id={question.id}
          />
        )}
        <YStack gap="$2">
          <QnAQuestionHeading question={question.question} />
          <SizableText
            tag="p"
            maxWidth={400}
            mx="auto"
            color="$primary10"
            size="$3"
            ta="center"
            allowFontScaling={false}
            $gtXxxs={{
              size: '$4',
            }}
          >
            {question.instructions ||
              (question.maxChoices > 1
                ? `Choose ${question.maxChoices} answers below and hit Submit!`
                : `Choose your answer below and hit Submit!`)}
          </SizableText>
        </YStack>
      </YStack>

      <YStack gap="$2" w="100%">
        {answersWithOrder.map((answer) => (
          <QnAAnswer
            key={answer.id}
            type="select"
            answer={answer.answer}
            order={answer.order}
            onPress={() => handleSelect(answer)}
            isSelected={data.some((item) => item.id === answer.id)}
            pressStyle={{ opacity: 0.9 }}
            hoverStyle={{ opacity: 0.9 }}
            focusStyle={{ opacity: 0.9 }}
            cursor="pointer"
          />
        ))}
      </YStack>
      <Button
        disabled={data.length < 1}
        onPress={() =>
          onSubmit({
            answerIds: data.map((a) => a.id),
            questionId: question.id,
          })
        }
        size="$3"
        mx="auto"
        allowFontScaling={false}
        $gtSm={{
          size: '$4',
        }}
        loading={submitting}
      >
        Submit
      </Button>
    </YStack>
  )
}
