import React, { useEffect, useState } from 'react'
import { AnimatePresence, H2, YStack } from '@mythical/ui'
import { QnAIntro } from './QnAIntro'
import { QnADialog } from './QnADialog'
import { QnAQuestion } from './QnAQuestion'
import { QnAOutOfTime } from './QnAOutOfTime'
import { QnACompleted } from './QnACompleted'
import { QnATransitionSlide, QnATransitionZoom } from './QnATransition'
import { QnAReveal } from './QnAReveal'
import { QnARevealed } from './QnARevealed'
import { QnAQuestionType, QnAUserAnswer } from './QnACard.container'
import { UserStore } from 'app/store/user-store'
import { QnAOutOfTimeReady } from './QnAOutOfTimeReady'
import { QnACompletedLoggedOut } from './QnACompletedLoggedOut'
import { QnAError } from './QnAError'

export enum QnABlockState {
  LOADING,
  READY,
  COMPLETED,
  COMPLETED_LOGGED_OUT,
  RAN_OUT_OF_TIME,
  READY_TO_REVEAL,
}

export enum QnAGameState {
  LOADING,
  READY,
  STARTED,
  COMPLETED,
  COMPLETED_LOGGED_OUT,
  RAN_OUT_OF_TIME_READY, // Out of time, but can go to next question
  RAN_OUT_OF_TIME, // Game over
  READY_TO_REVEAL,
  REVEALED,
  ERROR,
}

const states = {
  [QnAGameState.LOADING]: 'Loading',
  [QnAGameState.READY]: 'Ready',
  [QnAGameState.STARTED]: 'Started',
  [QnAGameState.COMPLETED]: 'Completed',
  [QnAGameState.COMPLETED_LOGGED_OUT]: 'CompletedLoggedOut',
  [QnAGameState.RAN_OUT_OF_TIME_READY]: 'RanOutOfTimeReady',
  [QnAGameState.RAN_OUT_OF_TIME]: 'RanOutOfTime',
  [QnAGameState.READY_TO_REVEAL]: 'ReadyToReveal',
  [QnAGameState.REVEALED]: 'Revealed',
  [QnAGameState.ERROR]: 'Error',
}

export type QnACardProps = {
  id: string
  title: string
  introTitle: string
  introSubtitle?: string
  questions: QnAQuestionType[]
  closingDate: string
  nextOpeningDate?: string
  user?: UserStore['user']
  onSubmit: (data: QnAUserAnswer[]) => Promise<void>
  onSubmitLoggedOut: (data: QnAUserAnswer[]) => void
  hasNextQnA: boolean
  gameState: QnAGameState
  blockState: QnABlockState
  setGameState: React.Dispatch<React.SetStateAction<QnAGameState>>
  setBlockState: React.Dispatch<React.SetStateAction<QnABlockState>>
  submitting?: boolean
  showTeamSelector?: boolean
  setTeam?: React.Dispatch<React.SetStateAction<any>> 
}

export const QnACard = (props: QnACardProps) => {
  const {
    title,
    introTitle,
    introSubtitle,
    closingDate,
    questions,
    onSubmit,
    onSubmitLoggedOut,
    hasNextQnA,
    user,
    gameState,
    blockState,
    setGameState,
    setBlockState,
    submitting,
    showTeamSelector,
    setTeam
  } = props

  const [dialogOpen, setDialogOpen] = useState(
    user &&
      (gameState === QnAGameState.READY ||
        (gameState === QnAGameState.READY_TO_REVEAL && hasNextQnA))
  )

  const [answers, setAnswers] = React.useState<QnAUserAnswer[]>([])
  const [currentQuestion, setCurrentQuestion] = useState(
    questions[0] as QnAQuestionType
  )

  useEffect(() => {
    if (gameState === QnAGameState.STARTED) {
      setCurrentQuestion(questions[0] as QnAQuestionType)
    }
  }, [gameState, questions])

  const currentQuestionIndex = questions.indexOf(currentQuestion)

  const handleCompletion = async (answers: QnAUserAnswer[]) => {
    if (!user) {
      setGameState(QnAGameState.COMPLETED_LOGGED_OUT)
      setBlockState(QnABlockState.COMPLETED_LOGGED_OUT)
      onSubmitLoggedOut(answers)
      return
    }

    try {
      await onSubmit(answers)

      if (!answers.length) {
        setGameState(QnAGameState.RAN_OUT_OF_TIME)
        setBlockState(QnABlockState.RAN_OUT_OF_TIME)
        return
      }

      setGameState(QnAGameState.COMPLETED)
      setBlockState(QnABlockState.COMPLETED)
    } catch (e) {
      setGameState(QnAGameState.ERROR)
      setBlockState(QnABlockState.READY)
    }
  }

  // Submit answer and move to next question
  const handleSubmit = (data: QnAUserAnswer) => {
    const nextQuestion = questions[currentQuestionIndex + 1]
    setAnswers((prevAnswers) => {
      const answers = [...prevAnswers]

      if (data.answerIds.length) {
        answers.push(data)
      }

      if (nextQuestion) {
        setCurrentQuestion(nextQuestion)
      } else {
        handleCompletion(answers)
      }

      return answers
    })
  }

  // Ran out of time, submit and prepare to move to next question,
  // if there's no more questions - game over
  const handleTimeOut = (data: QnAUserAnswer) => {
    const nextQuestion = questions[currentQuestionIndex + 1]
    if (nextQuestion) {
      setGameState(QnAGameState.RAN_OUT_OF_TIME_READY)
    } else {
      // Transition for the last question causes the timer to keep running for a sec
      // Need to make sure we don't timeout if the user has already completed the quiz
      setGameState((prevState) => {
        if (prevState === QnAGameState.COMPLETED) return prevState

        handleSubmit(data)
      })
    }
  }

  // Ran out of time, but can go to next question
  const handleTimeOutReady = () => {
    const nextQuestion = questions[currentQuestionIndex + 1]
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion)
      setGameState(QnAGameState.STARTED)
    }
  }

  // Called when the user finishes viewing revealed answers
  const handleNextQnA = () => {
    if (!hasNextQnA) return
    setGameState(QnAGameState.READY)
  }

  const dialogTitle =
    {
      [QnAGameState.STARTED]: `Question ${currentQuestionIndex + 1}`,
      [QnAGameState.REVEALED]: 'Answers revealed',
    }[gameState] || title

  console.log('QnACard', questions, currentQuestion, answers)

  return (
    <YStack>
      <YStack pos="relative" w="100%" gap="$3.5">
        <H2
          fontSize="$7"
          lineHeight="$8"
          tt="uppercase"
          fontWeight="500"
          color="$primary10"
          ta="center"
          $gtSm={{
            fontSize: '$8',
          }}
        >
          {title}
        </H2>
        {(blockState === QnABlockState.READY ||
          blockState === QnABlockState.LOADING) && (
          <QnAIntro
            title={introTitle}
            subtitle={introSubtitle}
            onStart={() => {
              setDialogOpen(true)
              setGameState(QnAGameState.STARTED)
            }}
            closingDate={closingDate}
            loading={blockState === QnABlockState.LOADING}
            showTeamSelector={showTeamSelector}
            setTeam={setTeam}
          />
        )}
        {blockState === QnABlockState.RAN_OUT_OF_TIME && (
          <QnAOutOfTime closingDate={closingDate} />
        )}
        {blockState === QnABlockState.COMPLETED && (
          <QnACompleted
            user={user}
            closingDate={closingDate}
            streak={user?.quizStreak || 0}
          />
        )}
        {blockState === QnABlockState.COMPLETED_LOGGED_OUT && (
          <QnACompletedLoggedOut />
        )}
        {blockState === QnABlockState.READY_TO_REVEAL && (
          <QnAReveal
            closingDate={closingDate}
            nextOpeningDate={props.nextOpeningDate}
            onReveal={() => {
              setDialogOpen(true)
              setGameState(QnAGameState.REVEALED)
            }}
          />
        )}
      </YStack>

      {/* Dialog */}
      <QnADialog
        title={dialogTitle}
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        state={gameState}
      >
        <YStack pos="relative" bg="$secondary9" w="100%">
          <AnimatePresence>
            {(gameState === QnAGameState.READY ||
              gameState === QnAGameState.LOADING) && (
              <QnATransitionZoom key="ready">
                <QnAIntro
                  key="intro"
                  title={introTitle}
                  subtitle={introSubtitle}
                  onStart={() => setGameState(QnAGameState.STARTED)}
                  closingDate={closingDate}
                  loading={gameState === QnAGameState.LOADING}
                  showTeamSelector={showTeamSelector}
                  setTeam={setTeam}
                  isDialog
                />
              </QnATransitionZoom>
            )}
            {gameState === QnAGameState.STARTED &&
              questions.map((question, index) =>
                question.id === currentQuestion.id ? (
                  <QnATransitionSlide
                    key={question.id}
                    noExitAnimation={index === questions.length - 1}
                  >
                    <QnAQuestion
                      question={currentQuestion}
                      closingDate={closingDate}
                      onSubmit={handleSubmit}
                      onTimeOut={handleTimeOut}
                      submitting={submitting}
                    />
                  </QnATransitionSlide>
                ) : null
              )}
            {gameState === QnAGameState.RAN_OUT_OF_TIME_READY && (
              <QnATransitionZoom key="outOfTimeReady">
                <QnAOutOfTimeReady
                  key="outOfTimeReady"
                  onNext={handleTimeOutReady}
                  closingDate={closingDate}
                  nextQuestionIndex={currentQuestionIndex + 2}
                />
              </QnATransitionZoom>
            )}
            {gameState === QnAGameState.RAN_OUT_OF_TIME && (
              <QnATransitionZoom key="outOfTime">
                <QnAOutOfTime closingDate={closingDate} isDialog />
              </QnATransitionZoom>
            )}
            {gameState === QnAGameState.COMPLETED && (
              <QnATransitionZoom key="completed">
                <QnACompleted
                  user={user}
                  closingDate={closingDate}
                  streak={user?.quizStreak || 0}
                  onClose={() => setDialogOpen(false)}
                />
              </QnATransitionZoom>
            )}
            {gameState === QnAGameState.COMPLETED_LOGGED_OUT && (
              <QnATransitionZoom key="completedLoggedOut">
                <QnACompletedLoggedOut isDialog />
              </QnATransitionZoom>
            )}
            {gameState === QnAGameState.READY_TO_REVEAL && (
              <QnATransitionZoom key="readyToReveal">
                <QnAReveal
                  closingDate={closingDate}
                  nextOpeningDate={props.nextOpeningDate}
                  onReveal={() => setGameState(QnAGameState.REVEALED)}
                />
              </QnATransitionZoom>
            )}
            {gameState === QnAGameState.REVEALED && (
              <QnATransitionZoom key="revealed">
                <QnARevealed
                  closingDate={closingDate}
                  questions={questions}
                  onNext={handleNextQnA}
                  hasNextQnA={hasNextQnA}
                  nextOpeningDate={props.nextOpeningDate}
                />
              </QnATransitionZoom>
            )}

            {gameState === QnAGameState.ERROR && (
              <QnATransitionZoom key="error">
                <QnAError closingDate={closingDate} isDialog />
              </QnATransitionZoom>
            )}
          </AnimatePresence>
        </YStack>
      </QnADialog>
    </YStack>
  )
}
