import React from 'react'
import { SizableText, YStack } from '@mythical/ui'
import { QnATimer } from './QnATimer'
import { Button } from 'app/components/primitive/Button'

export type QnARevealProps = {
  closingDate: string
  nextOpeningDate?: string
  onReveal: () => void
}

export const QnAReveal = (props: QnARevealProps) => {
  const { closingDate, nextOpeningDate, onReveal } = props

  const showClosingDate = new Date(closingDate).getTime() > Date.now()

  return (
    <YStack
      pos="relative"
      bg="$secondary9"
      gap="$3.5"
      jc="center"
      ai="center"
      p="$3.5"
      br="$3"
    >
      <SizableText
        tag="h3"
        ff="$heading"
        color="$primary1"
        size="$7"
        fontWeight="500"
        tt="uppercase"
        ta="center"
        allowFontScaling={false}
        $gtSm={{ size: '$8', fontWeight: '500' }}
      >
        Let’s check out your answers for the previous Think It & Sink It game!
      </SizableText>

      <Button
        onPress={onReveal}
        size="$3"
        mx="auto"
        allowFontScaling={false}
        $gtSm={{ size: '$4' }}
      >
        Reveal Answers
      </Button>

      {showClosingDate && <QnATimer date={closingDate} label="ENDS IN" />}
      {!showClosingDate && nextOpeningDate && (
        <QnATimer date={nextOpeningDate} label="NEXT GAME" />
      )}
    </YStack>
  )
}
