import React from 'react'
import { SizableText, YStack } from '@mythical/ui'

import { Button } from 'app/components/primitive/Button'
import { QnATimer } from './QnATimer'

export type QnAOutOfTimeReadyProps = {
  closingDate: string
  nextQuestionIndex: number
  onNext: () => void
}

export const QnAOutOfTimeReady = (props: QnAOutOfTimeReadyProps) => {
  const { closingDate, nextQuestionIndex, onNext } = props
  return (
    <YStack
      pos="relative"
      bg="$secondary9"
      gap="$3.5"
      jc="center"
      ai="center"
      p="$3.5"
      br="$3"
    >
      <YStack gap="$2">
        <SizableText
          tag="h3"
          ff="$heading"
          color="$primary1"
          size="$7"
          fontWeight="500"
          tt="uppercase"
          ta="center"
          allowFontScaling={false}
          $gtXxxs={{ size: '$8', fontWeight: '500' }}
        >
          OOPS YOU RAN OUT OF TIME!
        </SizableText>

        <SizableText
          tag="p"
          maxWidth={400}
          mx="auto"
          color="$primary10"
          size="$4"
          ta="center"
          allowFontScaling={false}
        >
          You can continue onto Question {nextQuestionIndex}
        </SizableText>
      </YStack>

      <Button
        size="$3"
        mx="auto"
        $gtSm={{ size: '$4' }}
        onPress={onNext}
        allowFontScaling={false}
      >
        Next
      </Button>

      <QnATimer date={closingDate} label="ENDS IN" />
    </YStack>
  )
}
