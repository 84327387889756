import { isWeb } from '@mythical/ui'
import { UserStore } from 'app/store/user-store'

// @ts-ignore they broke their types
import type { PlaylistItem } from '@jwplayer/jwplayer-react-native'
import * as Device from 'expo-device'
import { isAndroid, isIOS } from 'app/utils/constants'

export interface VideoProps {
  id: string
  url: string
  title?: string
  thumbnail?: string
  player: string
  captions?: string
  data?: { playlist?: any[] }
  isUnlocked?: boolean
  contentId?: string
  userStore?: UserStore
  currentProgress?: number
  previewPercentage?: number
  onVideoPlay?: () => void
  onVideoPause?: () => void
  onVideoEnd?: () => void
  onVideoProgress?: (progress: number) => void
  onVideoPreviewEnd?: () => void
}

interface ExPlaylistItem extends PlaylistItem {
  starttime: number
}

export const propsToPlaylistItem = (props: VideoProps): ExPlaylistItem => {
  console.log('props.data?.playlist', props.data?.playlist)

  if (props.data?.playlist && isWeb) {
    const item = props.data.playlist[0] as ExPlaylistItem

    delete item.title
    delete item.description

    item.startTime = props.currentProgress || 0
    item.starttime = props.currentProgress || 0

    return item
  }

  let tracks: any[] = []

  if (props.captions) {
    tracks = [
      {
        file: props.captions,
        label: 'English',
        kind: 'captions',
        default: true
      }
    ]
  }

  return {
    file: props.url,
    image: props.thumbnail,
    tracks: tracks || [],
    startTime: props.currentProgress || 0,
    starttime: props.currentProgress || 0,
  }
}
