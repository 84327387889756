import React, { useEffect, useState } from 'react'
import { SizableText, Stack, XStack, YStack } from '@mythical/ui'
import { formateDistanceToNow } from './helpers'
import { Avatar } from 'app/components/primitive/Avatar'
import { UserStore } from 'app/store/user-store'
import { Button } from 'app/components/primitive/Button'

export type QnACompletedProps = {
  closingDate: string
  user: UserStore['user']
  streak: number
  onClose?: () => void
}

export const QnACompleted = (props: QnACompletedProps) => {
  const { closingDate, user, streak, onClose } = props

  const [displayedEndDate, setDisplayedEndDate] = useState(
    formateDistanceToNow(new Date(closingDate))
  )
  const hasEnded = new Date(closingDate) < new Date()

  useEffect(() => {
    if (hasEnded) return
    const interval = setInterval(() => {
      setDisplayedEndDate(formateDistanceToNow(new Date(closingDate)))
    }, 1000)

    return () => clearInterval(interval)
  }, [closingDate])

  return (
    <YStack
      pos="relative"
      bg="$secondary9"
      gap="$8"
      jc="center"
      ai="center"
      p="$3.5"
      br="$3"
    >
      <YStack gap="$1">
        <SizableText
          tag="h3"
          ff="$heading"
          color="$primary1"
          size="$7"
          fontWeight="500"
          tt="uppercase"
          ta="center"
          $gtSm={{ size: '$8', fontWeight: '500' }}
          allowFontScaling={false}
        >
          THANKS FOR PLAYING!
        </SizableText>

        {!hasEnded && (
          <SizableText
            ff="$heading"
            ta="center"
            size="$5"
            fow="500"
            color="$primary10"
            allowFontScaling={false}
          >
            CHECK OUT THE ANSWERS IN:{' '}
            <SizableText
              ff="$heading"
              size="$5"
              fow="500"
              color="$green3"
              allowFontScaling={false}
            >
              {displayedEndDate}
            </SizableText>
          </SizableText>
        )}
      </YStack>

      <YStack gap="$3.5" w="100%" jc="center">
        {user && (
          <XStack
            pos="relative"
            p="$3.5"
            bg="$secondary8"
            ai="center"
            jc="space-between"
            gap="$3.5"
            br="$3"
          >
            <XStack
              pos="absolute"
              jc="center"
              left={0}
              right={0}
              top="-25%"
              mx="auto"
            >
              <Stack p="$2" bg="$primary8" br="$3">
                <SizableText
                  ff="$heading"
                  size="$5"
                  fontWeight="500"
                  color="$secondary10"
                  tt="uppercase"
                  allowFontScaling={false}
                >
                  Your streak
                </SizableText>
              </Stack>
            </XStack>
            <XStack gap="$2" ai="center" flex={1}>
              <Avatar
                src={user?.profileImage}
                width={80}
                height={80}
                tier={user?.tier}
              />
              <SizableText
                ff="$heading"
                size="$6"
                fontWeight="500"
                color="$primary1"
                tt="uppercase"
                flex={1}
                allowFontScaling={false}
              >
                {user?.fullName}
              </SizableText>
            </XStack>
            <SizableText
              ff="$heading"
              size="$10"
              fontWeight="500"
              color="$primary10"
              allowFontScaling={false}
            >
              {streak}
            </SizableText>
          </XStack>
        )}

        {onClose && (
          <Button
            onPress={onClose}
            size="$3"
            mx="auto"
            $gtSm={{ size: '$4' }}
            allowFontScaling={false}
          >
            Close
          </Button>
        )}
      </YStack>
    </YStack>
  )
}
