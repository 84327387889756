import JWPlayer from '@jwplayer/jwplayer-react'
import { Stack, useDidFinishSSR } from '@mythical/ui'
import React, { useRef, useCallback } from 'react'
import * as Sentry from '@sentry/nextjs'

import { propsToPlaylistItem, VideoProps } from './props'
import EventBatcher from 'app/lib/watch-tracker'

const VideoPlayer = (props: VideoProps) => {
  const hasMounted = useDidFinishSSR()
  const playerRef = useRef<JWPlayer>(null)

  const onPause = () => {
    props.onVideoPause?.()
  }

  const onPlay = () => {
    props.onVideoPlay?.()
  }

  const onTime = useCallback(
    (e) => {
      const progress = Math.round((e.position / e.duration) * 100)

      if (Math.round(e.position) % 5 === 0) {
        EventBatcher.getInstance(props.userStore).addEvent({
          content: props.contentId,
          duration: Math.round(e.duration),
          position: Math.round(e.position),
          videoProgress: progress,
        })
      }

      if (progress > (props?.previewPercentage || 10) && !props.isUnlocked) {
        playerRef.current?.player?.pause()
        playerRef.current?.player?.setFullscreen(false)
        return props?.onVideoPreviewEnd?.()
      }

      props.onVideoProgress?.(progress)
    },
    [props]
  )

  const onComplete = () => {
    props.onVideoEnd?.()
  }

  const onError = (e) => {
    console.error('error', e)
    Sentry.captureException(e)
  }

  const onPlayerReady = () => {
    playerRef.current?.player?.seek(props.currentProgress || 0)
  }

  return (
    <Stack pt="56.25%" flex={1} borderRadius="$3" overflow="hidden">
      <Stack
        pos="absolute"
        t="$0"
        l="$0"
        w="100%"
        h="100%"
        zIndex={1}
        bg="$secondary10"
      >
        {hasMounted && (
          <JWPlayer
            key={props.id}
            library={props.player}
            playlist={[propsToPlaylistItem(props)]}
            onPlay={onPlay}
            onPause={onPause}
            onTime={onTime}
            onComplete={onComplete}
            onError={onError}
            onReady={onPlayerReady}
            ref={playerRef}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default VideoPlayer
