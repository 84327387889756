import React, { useEffect } from 'react'
import { useStores } from 'app/store'
import {
  QnABlockState,
  QnACard as QnACardComponent,
  QnAGameState,
} from './QnACard'
import useMutation from 'app/hooks/use-mutation'
import { voteMutation, voteResult } from 'app/mutations/polls/vote'
import { Answer } from 'app/store/quiz-store'
import { AppState } from 'react-native'
import useAnalytics from 'app/lib/analytics'
import {
  memberTeamResult,
  createMemberTeamMutation,
} from 'app/queries/memberTeam'

export type QnAQuestionChoiceAnswerType = {
  answer: string
  correct: boolean
  votes?: number
  percent: number
  yourVote?: boolean
  totalPoints?: number
  id: string
}

export type QnAQuestionChoiceType = {
  id: string
  question: string
  instructions?: string
  maxChoices: number
  timeLimit: number
  answers: QnAQuestionChoiceAnswerType[]
  __typename: 'SingleChoice' | 'MultipleChoice'
}

export type QnAQuestionRankingAnswerType = {
  answer: string
  correctPosition: number
  averagePosition: number
  yourPosition?: number
  totalPoints?: number
  id: string
}

export type QnAQuestionRankingType = {
  id: string
  question: string
  instructions?: string
  timeLimit: number
  answers: QnAQuestionRankingAnswerType[]
  __typename: 'Ranking'
}

export type QnAQuestionType = QnAQuestionChoiceType | QnAQuestionRankingType

export type QnAUserAnswer = {
  questionId: string
  answerIds: string[]
}

export type QnAPollProps = {
  id: string
  title: string
  introTitle: string
  introSubtitle?: string
  questions: QnAQuestionType[]
  hasAnswered: boolean
  closingDate: string
}

export type QnACardProps = {
  previous: QnAPollProps
  current: QnAPollProps
  next: {
    openingDate: string
  }
  season: {
    id: string
    enableTeams?: boolean
  }
  memberTeam?: string
}

const voteQuery = voteMutation()
const memberTeamQuery = createMemberTeamMutation()

export const QnACard = (props: QnACardProps) => {
  const { userStore, quizStore } = useStores()
  const analytics = useAnalytics()

  const [team, setTeam] = React.useState(props?.memberTeam)
  const [poll, setPoll] = React.useState<QnAPollProps>()
  const mutation = useMutation(voteQuery, voteResult)
  const teamMutation = useMutation(memberTeamQuery, memberTeamResult)
  const [gameState, setGameState] = React.useState<QnAGameState>(
    QnAGameState.READY
  )
  const [blockState, setBlockState] = React.useState<QnABlockState>(
    QnABlockState.READY
  )

  const onAppStateChanged = (nextAppState) => {
    console.log('App state changed: ', nextAppState, gameState)
    if (nextAppState !== 'active' && gameState === QnAGameState.STARTED) {
      quizStore?.setClosedApp(true)
      setGameState(QnAGameState.RAN_OUT_OF_TIME)
      setBlockState(QnABlockState.RAN_OUT_OF_TIME)
      handleVote(props.current?.id, quizStore?.answers)
    }
  }

  useEffect(() => {
    const subscription = AppState.addEventListener('change', onAppStateChanged)

    return () => {
      subscription.remove()
    }
  }, [gameState, quizStore])

  useEffect(() => {
    if (!userStore) return

    const hasPreviousAnswers =
      props.previous?.questions.some((q) =>
        q.answers.some((a) => a.yourVote || a.yourPosition)
      ) || props?.previous?.hasAnswered

    const hasCurrentAnswers = props.current?.questions.some((q) =>
      q.answers.some((a) => a.yourVote || a.yourPosition)
    )

    // if the user ran out of time on the current poll, show the ran out of time screen
    if (
      (props?.current && props.current.hasAnswered && !hasCurrentAnswers) ||
      (quizStore?.closedApp && quizStore?.id === props.current?.id)
    ) {
      setPoll(props.current)
      setGameState(QnAGameState.RAN_OUT_OF_TIME)
      setBlockState(QnABlockState.RAN_OUT_OF_TIME)
      analytics.logEvent('quiz_ran_out_of_time', {
        quiz_id: props.current?.id,
        season: props.season?.id,
      })

      return
    }

    // if the current poll exists and the user has completed it, then set as completed
    if (props.current && hasCurrentAnswers) {
      setPoll(props.current)
      setGameState(QnAGameState.COMPLETED)
      setBlockState(QnABlockState.COMPLETED)

      return
    }

    // if the previous poll exists and the user has completed it, then show the previous poll
    if (props.previous && hasPreviousAnswers) {
      setPoll(props.previous)
      setGameState(QnAGameState.READY_TO_REVEAL)
      setBlockState(QnABlockState.READY_TO_REVEAL)

      return
    }

    // if the current poll exists and the user has not completed it, then show the current poll
    if (props.current && !props.current.hasAnswered) {
      setPoll(props.current)
      setGameState(QnAGameState.READY)
      setBlockState(QnABlockState.READY)
      analytics.logEvent('quiz_start', {
        quiz_id: props.current?.id,
        season: props.season?.id,
      })
    }

    if (
      props.current &&
      quizStore?.isLoggedOutSubmission &&
      quizStore?.id === props.current?.id
    ) {
      setPoll(props.current)
      if (userStore?.user) {
        setGameState(QnAGameState.COMPLETED)
        setBlockState(QnABlockState.COMPLETED)
        handleVote(props.current?.id, quizStore?.answers)
        handleTeamSelect(quizStore?.memberTeam)
      } else {
        setGameState(QnAGameState.COMPLETED_LOGGED_OUT)
        setBlockState(QnABlockState.COMPLETED_LOGGED_OUT)
      }
    }

    if (!userStore?.user && quizStore?.memberTeam) {
      setTeam(quizStore?.memberTeam)
    }
  }, [props.current, props.previous, quizStore, userStore])

  const handleSetGameState = (state: QnAGameState) => {
    if (gameState !== QnAGameState.READY && state === QnAGameState.READY) {
      setGameState(state)
      setBlockState(QnABlockState.READY)
      setPoll(props.current)
      return
    }

    setGameState(state)
  }

  const handleVote = async (id: string, answers: QnAUserAnswer[]) => {
    if (!userStore?.user?.id) {
      return
    }

    const variables = {
      id,
      answers,
      season: props.season?.id,
    }

    console.log('Submitting answers: ', JSON.stringify(variables, null, 2))

    try {
      const res = await mutation.makeRequest(variables)
      console.log('Vote result: ', res)
      userStore.setUser({
        ...userStore.user,
        quizStreak: (userStore.user.quizStreak || 0) + 1,
      })
      analytics.logEvent('quiz_submit', {
        quiz_id: props.current?.id,
        season: props.season?.id,
        wasLoggedOut: quizStore?.isLoggedOutSubmission,
      })
    } catch (e) {
      console.log('Error submitting answers: ', e)
    } finally {
      userStore.refreshAccessToken()
    }
  }

  const handleSubmitAnswersLoggedOut = async (answers: QnAUserAnswer[]) => {
    console.log('Answers logged out: ', answers)
    analytics.logEvent('quiz_submit_logged_out', {
      quiz_id: props.current?.id,
      season: props.season?.id,
    })

    quizStore?.setAnswers(answers as Answer[])
    quizStore?.setId(id)
    quizStore?.setIsLoggedOutSubmission(true)
    quizStore?.setSeason(props.season?.id)
  }

  const handleTeamSelect = async (team: string) => {
    if (!team) return

    if (userStore?.user) {
      try {
        const variables = { team, seasonId: props?.season?.id }
        const res = await teamMutation.makeRequest(variables)
        if (res?.success) setTeam(team)
        console.log('team select result: ', res)
      } catch (e) {
        console.log('Error selecting team: ', e)
      }
    } else {
      console.log('Team select logged out: ', team)
      analytics.logEvent('quiz_team_select_logged_out', {
        quiz_id: props.current?.id,
        season: props.season?.id,
        team,
      })
      quizStore?.setMemberTeam(team)
      setTeam(team)
    }
  }

  if (!poll) {
    return null
  }

  const { id, title, introTitle, introSubtitle, closingDate, questions } = poll

  const handleSubmitAnswers = async (answers: QnAUserAnswer[]) => {
    await handleVote(poll?.id, answers)
  }

  return (
    <QnACardComponent
      id={id}
      user={userStore?.user}
      title={title}
      introTitle={introTitle}
      introSubtitle={introSubtitle}
      questions={questions}
      closingDate={closingDate}
      hasNextQnA={!!props.current}
      nextOpeningDate={props.next?.openingDate}
      gameState={gameState}
      blockState={blockState}
      setGameState={handleSetGameState}
      setBlockState={setBlockState}
      onSubmit={handleSubmitAnswers}
      onSubmitLoggedOut={handleSubmitAnswersLoggedOut}
      submitting={mutation.loading}
      setTeam={handleTeamSelect}
      showTeamSelector={!!props?.season?.enableTeams && !team}
    />
  )
}
