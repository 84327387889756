import { Video as VideoType } from 'app/blocks/video'
import React from 'react'

import VideoPlayer from '../primitive/VideoPlayer'

interface Props {
  title?: string
  thumbnail?: string
  block: VideoType
  isUnlocked: boolean
  onVideoPlay?: () => void
  onVideoPause?: () => void
  onVideoEnd?: () => void
  onVideoProgress?: (progress: number) => void
}

export const VideoBlock: React.FC<Props> = ({ block, ...rest }) => {
  const data = JSON.parse(block.videoData)
  return (
    <VideoPlayer
      id={block.id}
      url={block.video}
      player={block.player}
      data={data}
      captions={block.closedCaptions}
      {...rest}
    />
  )
}
