import React from 'react'
import { YStack } from '@mythical/ui'
import { QnAQuestionType } from './QnACard.container'
import QnAAnswers from './QnAAnswers'
import { Button } from 'app/components/primitive/Button'
import { QnATimer } from './QnATimer'

export type QnARevealedProps = {
  closingDate: string
  nextOpeningDate?: string
  questions: QnAQuestionType[]
  hasNextQnA: boolean
  onNext: () => void
}

export const QnARevealed = (props: QnARevealedProps) => {
  const { closingDate, nextOpeningDate, questions, onNext, hasNextQnA } = props

  const showClosingDate = new Date(closingDate).getTime() > Date.now()

  return (
    <YStack
      pos="relative"
      bg="$secondary9"
      gap="$3.5"
      jc="center"
      ai="center"
      p="$3.5"
    >
      <QnAAnswers questions={questions} />

      {hasNextQnA && (
        <Button
          size="$3"
          mx="auto"
          allowFontScaling={false}
          $gtSm={{ size: '$4' }}
          onPress={onNext}
        >
          Next game
        </Button>
      )}

      {showClosingDate && <QnATimer date={closingDate} label="ENDS IN" />}
      {!showClosingDate && nextOpeningDate && (
        <QnATimer date={nextOpeningDate} label="NEXT GAME" />
      )}
    </YStack>
  )
}
