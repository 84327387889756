import React from 'react'
import { SizableText, Stack, YStack } from '@mythical/ui'
import { SolitoImage } from 'solito/image'
import { QnATimer } from './QnATimer'

export type QnAOutOfTimeProps = {
  closingDate: string
  isDialog?: boolean
}

export const QnAOutOfTime = (props: QnAOutOfTimeProps) => {
  const { closingDate, isDialog } = props
  return (
    <YStack
      pos="relative"
      bg="$secondary9"
      gap="$3.5"
      jc="center"
      ai="center"
      p="$3.5"
      br="$3"
    >
      {isDialog && (
        <Stack w={36} h={57} $gtSm={{ w: 63, h: 103 }}>
          <SolitoImage
            src={require('../../../assets/images/logo--full.png')}
            fill
            alt=""
          />
        </Stack>
      )}

      <YStack gap="$2">
        <SizableText
          tag="h3"
          ff="$heading"
          color="$primary1"
          size="$7"
          fontWeight="500"
          tt="uppercase"
          ta="center"
          allowFontScaling={false}
          $gtSm={{ size: '$8', fontWeight: '500' }}
        >
          Oops you ran out of time!
        </SizableText>

        <SizableText
          tag="p"
          maxWidth={400}
          mx="auto"
          color="$primary10"
          size="$4"
          ta="center"
          allowFontScaling={false}
        >
          Unfortunately you ran out of time and cannot complete todays Think It & Sink It game. Join
          us again tomorrow for another chance to play!
        </SizableText>
      </YStack>
      <QnATimer date={closingDate} label="ENDS IN" />
    </YStack>
  )
}
